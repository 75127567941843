<template>
  <b-navbar toggleable="md" type="dark" class="bg-gradient-dark">
    <b-navbar-brand href="#">
      <b-img src="@/assets/images/logo.svg" width="50" height="auto" alt="Logo TripleStack" rel="prefetch"/>
      <div class="h2 d-inline ml-2 m-0 align-middle">TripleStack</div>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item href="#" v-for="(link, idx) in links" v-bind:key="idx" v-scroll-to="link.id">{{
            link.name
          }}
        </b-nav-item>
        <b-nav-form>
          <b-button-group>
            <b-button variant="primary" href="#" v-scroll-to="'#contact'">
              Nous contacter
            </b-button>
            <b-button variant="secondary" href="https://support.triplestack.fr" target="_blank"
                      v-if="feature_flags.enable_support">
              Support
            </b-button>
          </b-button-group>
        </b-nav-form>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
const config = require('../config/');
export default {
  name: "Nav",
  data() {
    return {
      feature_flags: config.feature_flags,
      links: [
        {name: 'A propos', id: '#about'},
        {name: 'Expertise', id: '#expertise'},
        {name: 'Solutions', id: '#solutions'},
      ]
    }
  },
}
</script>

<style scoped lang="scss">

</style>