<template>
  <div id="app" class="w-100">
    <Nav/>
    <Header/>
    <About class="bg-gradient-dark text-light" id="about"/>
    <TheBlock class="bg-gradient-light">
      <b-container class="h3 text-center quote">
        Chez TripleStack, nous pensons que la <strong>performance</strong> et la <strong>sécurité</strong> sont
        primordiales.
      </b-container>
    </TheBlock>
    <Expertise class="bg-gradient-dark text-light" id="expertise"/>
    <Solutions class="bg-gradient-light" id="solutions"/>
    <Contact id="contact" class="bg-gradient-dark text-light"/>
    <Footer class="bg-dark"/>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import Expertise from "./components/Expertise";
import Solutions from "./components/Solutions";
import Contact from "./components/Contact";
import About from "./components/About";
import TheBlock from "./components/_common/TheBlock";
import Nav from "./components/Nav";

export default {
  name: 'App',
  components: {
    Nav,
    TheBlock,
    About,
    Contact,
    Solutions,
    Expertise,
    Footer,
    Header
  }
}
</script>

<style lang="css">
body {
  background-color: white !important;
}
</style>
