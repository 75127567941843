<template>
  <header>
    <div class="jumb d-flex justify-content-center justify-content-md-start">
      <h1 class="d-block align-self-center rounded">
        {{ h1_title }}
      </h1>
    </div>
  </header>
</template>

<script>
const config = require('../config/');

export default {
  name: "Header",
  data() {
    return {
      links: [
        {name: 'A propos', id: '#about'},
        {name: 'Expertise', id: '#expertise'},
        {name: 'Solutions', id: '#solutions'},
      ],
      h1_title: null
    }
  },
  created() {
    this.h1_title = config.seo_title.replace('- TripleStack', '');
  },
}
</script>

<style scoped lang="scss">
@import "~@coreui/coreui/scss/functions";
@import "~@coreui/coreui/scss/variables";
@import "~@coreui/coreui/scss/mixins/breakpoints";

header {
  border-bottom: 2px solid $dark !important;
  border-top: 2px solid $dark !important;
}

.jumb {
  @include media-breakpoint-down(sm) {
    max-height: 10rem;
    background-image: url("../assets/images/header_576.jpg");
  }

  @include media-breakpoint-up(sm) {
    padding-left: 5rem !important;
    max-height: 15rem;
    background-image: url("../assets/images/header_1920.jpg");
  }

  @include media-breakpoint-up(md) {
    max-height: 20rem;
  }

  @include media-breakpoint-up(lg) {
    max-height: 25rem;
  }

  @include media-breakpoint-up(xl) {
    max-height: 30rem;
  }

  @include media-breakpoint-up(xxl) {
    max-height: 40rem;
  }

  @media (min-width: 2000px) {
    background-image: url("../assets/images/header_4096.jpg");
  }

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  height: 40rem;
  padding: 0 0.25rem 0 0.25rem;
}

h1 {
  text-transform: uppercase;
  font-weight: bold;
  color: $warning-dark !important;
  padding: 0.6rem !important;
  margin: 0.5rem !important;
  background-color: transparentize($black, 0.2);
  border: 1px solid transparentize($primary-dark, 0.9);

  max-width: 50%;
  text-align: center;

  @include media-breakpoint-down(sm) {
    font-size: 24px;
    max-width: 75%;
    padding: 0.25rem !important;
  }
}
</style>