<template>
  <footer>
    <div class="text-center p-3">
      <b-img-lazy width="35" height="auto" src="@/assets/images/logo.svg" class="mr-2" alt="Logo TripleStack"/>
      <span>TripleStack SAS - 853 010 064 R.C.S. Lyon</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>